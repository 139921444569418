@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');
@import url('fonts/font.css');

body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

*, :before, :after {
  box-sizing: unset;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
  div#GitHelper *,
  div#GitHelper *:before,
  div#GitHelper *:after {
    box-sizing: border-box;
    border: 0 solid #e5e7eb;
  }
  div#GitHelper{
    @apply fixed scroll-smooth z-[1106] pointer-events-auto select-none;
    font-family: "Quicksand", 'Noto Sans TC',  sans-serif;
  }
  div#GitHelper .svg-w-full svg { @apply w-full pointer-events-none; }
  div#GitHelper .svg-h-full svg { @apply h-full pointer-events-none; }
  div#GitHelper .fade-div-hide .img-btn {@apply pointer-events-none; }
  div#GitHelper .fade-div-hide .pointer-events-auto {@apply pointer-events-none; }
  div#GitHelper .img-btn { @apply pointer-events-auto cursor-pointer select-none z-20; -webkit-tap-highlight-color: transparent;}
  div#GitHelper .img-btn:focus { outline: none; box-shadow: none; }
  div#GitHelper .img-btn > img { @apply w-full object-contain select-none; }
  div#GitHelper .team-controlled .img-btn,
  div#GitHelper .team-controlled .pointer-events-auto {pointer-events: none;}
  div#GitHelper .team-controlled .canTouch{pointer-events: auto;}
  div#GitHelper .team-controlled .canTouch .img-btn,
  div#GitHelper .team-controlled .canTouch .pointer-events-auto {pointer-events: auto;}
  div#GitHelper .bg-blur{backdrop-filter: blur(10px)}
  /*div#GitHelper .markdown {@apply text-base}*/
  div#GitHelper .markdown ol,ul{@apply py-4}
  div#GitHelper .markdown ol{@apply list-decimal ml-7}
  div#GitHelper .markdown ul{@apply list-disc ml-7}
  div#GitHelper .markdown a{@apply text-[#98E9FB]  opacity-90 hover:opacity-100 cursor-pointer}
  div#GitHelper .info .markdown del{text-decoration: none; @apply text-[#b3b3b3] font-normal}
  div#GitHelper .info .markdown del.text-justify{text-align: start}
  div#GitHelper .info .markdown h4{@apply text-xl block mt-2 mb-2 text-[#E2B863]}
  div#GitHelper .info .markdown h3{@apply text-2xl block font-bold mt-4 mb-4}

  div#GitHelper .scroll-none::-webkit-scrollbar {
    -webkit-appearance: none;
    display: none;
  }

  /*取消移動端點擊高亮*/
  div#GitHelper {-webkit-tap-highlight-color: rgba(0,0,0,0);}
  @media (prefers-color-scheme: dark){
    body{
      background-color: #FFFFFF;
      color: #000000;
    }
  }
}