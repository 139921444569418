@font-face {
    font-family: makinas;
    src: url("makinas.otf");
}
@font-face {
    font-family: ud;
    src: url("ud-R.ttf");
}
@font-face {
    font-family: ud-b;
    src: url("ud-B.ttf");
}
@font-face {
    font-family: seto;
    src: url("seto-R.ttf");
}